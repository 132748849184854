$c_primary: #0d0e12;
$c_secondary: #1c1c21;
$c_secondary_text: #9a9cae;
$c_primary_hover: #1c1c21;
$c_primary_text: #252F4A;
$c_form_border: #72cedd;
$c_white: #fff;
$c_black: #252f4a;
$c_red: #ff0000;
$c_red_hover: #c20303;
$c_text_grey: #999;
$shadow: rgba(0, 0, 0, 0.08) 0px 2px 25px 0px;
$shadow2: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
$shadow3: 0px 3px 4px 0px rgba(0, 0, 0, .03);
$transition: all 0.3s ease-in-out 0s;
$blue: #1B84FF;
$dark_blue: #056ee9;

body {
  background-color: #fcfcfc;
}

// Common
.anchor_link {
  color: $c_primary;
  transition: $transition;
  cursor: pointer;

  &:hover {
    color: $c_primary_hover;
  }
}

// Button
.MuiButton-root {
  &.btn {
    height: 50px;
    padding: 5px 20px;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.3px;
    box-shadow: none;
    text-transform: capitalize;
    gap: 5px;

    &:hover {
      box-shadow: none;
    }

    svg {
      font-size: 20px;
    }
  }

  &.btn_primary {
    color: $c_white;
    background-color: $c_primary;

    &:hover {
      background-color: $c_secondary;
      color: $c_white;
    }
  }

  &.btn_danger {
    color: $c_white;
    background-color: $c_red;

    &:hover {
      background-color: $c_red_hover;
    }
  }

  &.sm {
    height: 35px;
    padding: 5px 10px;
  }
}

// Forms
.custom_label[class*="MuiTypography"] {
  font-size: 14px;
  font-weight: 600;
  color: $c_primary_text;
  margin-bottom: 5px;
  letter-spacing: 0.3px;
  line-height: 1.2;
}

.MuiFormControl-root,
.MuiTextField-root,
.react-tel-input {
  >.MuiInputBase {
    &-root {
      position: relative;
      padding: 0;

      &::before,
      &::after {
        display: none;
      }
    }

    &-hiddenLabel {
      >fieldset {
        display: none;
      }
    }
  }

  .react-tel-input .form-control {
    width: 100% !important;
  }

  :is(input, textarea, select, input.form-control) {
    color: $c_black;
    font-weight: 500;
    height: 50px;
    box-sizing: border-box;
    font-size: 14px;
    border-radius: 5px !important;
    // border: 1px solid $c_form_border;
    box-shadow: none !important;

    // &:focus {
    //   // border-color: $c_primary_text;
    // }

    &::placeholder {
      color: #4B5675;
      opacity: 0.6;
      font-weight: 500;
    }
  }

  :is(input, select) {
    padding: 5px 15px;
  }

  :is(textarea) {
    padding: 8px 15px;
  }

  .Mui-disabled {
    -webkit-text-fill-color: black !important;
  }

  input:has(+ .eye_btn) {
    padding-right: 50px;
  }

  .eye_btn {
    position: absolute;
    height: 50px;
    max-height: 50px;
    width: 50px;
    margin: 0;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      height: 80%;
      width: 80%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      margin: 0 auto;

      svg {
        height: 20px;
        width: 20px;
      }
    }
  }
}

.MuiFormControlLabel-root {
  color: $c_black;
  line-height: 1.2;
  margin: 0 0 0 -3px !important;
  gap: 4px;

  .MuiCheckbox-root {
    padding: 0;
    color: $c_form_border;
    position: relative;
    top: -1px;

    &.Mui-checked {
      color: $c_primary;
    }
  }

  svg {
    width: 25px;
    height: 25px;
  }

  .MuiFormControlLabel-label {
    font-size: 14px;
    font-weight: 600;
    color: $c_primary_text;
    display: inline-flex;
  }
}

.form_btn {
  margin-top: 30px;
}

// Auth
.auth_module {
  min-height: 100vh;
  background-image: linear-gradient(180deg, $c_secondary, $c_primary);
  background-attachment: fixed;

  .auth_logo {
    margin-bottom: 30px;

    .logo_cntnr {
      height: 100px;
      margin: 0;

      img {
        height: 100%;
      }
    }
  }

  .auth_cntnr {
    max-width: 480px;
    border-radius: 0;
    background-color: transparent;
    padding: 0;
  }

  .auth_box {
    max-width: 100%;
    border-radius: 30px;
    background-color: $c_white;
    padding: 40px 40px;

    h5 {
      font-size: 20px;
      font-weight: 800;
    }
  }

  .anchor_link,
  .anchor_link p {
    font-size: 14px;
    font-weight: 600;
    display: inline-flex;
  }

  .opt_fields {
    max-width: 300px;
    margin-inline: auto;
    display: flex;
  }
}

// Sidebar
.sidebar-main-div {
  .sidebar_drawer {
    .MuiDrawer-paper {
      background-color: $c_primary;
    }
  }
}

.sidebar-logo {
  padding: 20px 20px;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 9;
  text-align: center;

  figure {
    width: 100%;
    height: 50px;
    margin: 0;

    img {
      max-height: 100%;
      max-width: 100%;
      cursor: pointer;
    }
  }
}

.sidebr-lst {
  // padding: 0 0 20px ;
  background-color: $c_primary;

  .lst-itm {
    padding: 5px 20px;
    min-height: 55px;
    gap: 10px;
    transition: $transition;

    &:hover {
      background-color: rgba($c_secondary, 0.2);
    }

    .MuiListItemIcon-root {
      height: 35px;
      min-width: 35px;
      color: $c_secondary;
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background: $c_white;

      svg {
        height: 20px;
        width: 20px;
        background: $c_white;
      }
    }

    .lstitm-txt {
      margin: 0;
      color: $c_secondary_text;
      display: inline-flex;
      transition: $transition;

      span {
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.3px;
        line-height: 1.2;
        transition: $transition;
      }
    }

    &.Mui-selected {
      border-color: $c_secondary;
      background-color: rgba($c_secondary, 0.6);

      .lstitm-txt {
        color: $c_white;

        span {
          font-weight: 600;
        }
      }
    }
  }
}

// Topbar
.top_bar {
  .topbar {
    box-shadow: $shadow;
    background-color: $c_white;

    .MuiToolbar-root {
      min-height: 70px;
      padding: 10px 20px;
      box-sizing: border-box;
    }
  }

  .topbar_user_btn {
    display: flex;
    align-items: center;
    padding: 5px 5px 5px 8px;
    border-radius: 5px;
    background-color: #f9f9f9;

    img {
      height: 40px;
      width: 40px;
      min-width: 40px;
      border-radius: 50%;
      margin-right: 8px;
    }

    span {
      font-size: 14px;
      font-weight: 600;
      color: #555;
    }

    svg {
      height: 20px;
      width: 20px;
      margin-left: 3px;
    }
  }

  .notif_bubble {
      color: white;
      position: absolute;
      right: 5px;
      z-index: 2;
      background: red;
      height: 15px;
      width: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50px;
      font-size: 12px;
  }

  .notification_btn,
  .chat_btn {
    height: 50px;
    width: 50px;
    border-radius: 5px;
    background-color: #f9f9f9;

    &:hover {
      color: $c_primary;
    }
  }

  .chat_btn {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      right: 10px;
      top: 10px;
      width: 7px;
      height: 7px;
      background-color: $c_secondary;
      border-radius: 50%;
      display: inline-flex;
      opacity: 0;
    }

    &.active {
      &::before {
        opacity: 1;
      }
    }
  }
}

.userDropdown_menu {
  .userDropdown_menu_list {
    padding: 10px 15px;
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    min-width: 120px;

    button {
      padding: 0;
      pointer-events: none;

      svg {
        height: 20px;
        width: 20px;
      }
    }
  }
}

.notiDropdown_menu {
  .MuiMenu-paper {
    max-width: 450px;
    overflow-y: auto;
  }

  ul.MuiList-padding {
    padding: 0;
  }

  .noti_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    padding: 10px 15px;
    position: sticky;
    top: 0;
    left: 0;
    background-color: $c_white;
    z-index: 2;

    h2 {
      font-size: 16px;
      font-weight: 700;
      margin: 0;
      color: $c_black;
    }

    .btn {
      height: 30px;
      font-size: 12px;
      font-weight: 600;
    }

  }

  figure {
    height: 50px;
    width: 50px;
    margin: 0 auto;
    img {
      height: 100%;
      width: 100%;
    }
  }
  
  .show_more {
    text-align: center;
    font-size: 12px;
    color: #1976D2;
    font-weight: 700;
    padding: 10px;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }

  .noti_list {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      display: flex;
      align-items: flex-start;
      padding: 10px 15px;
      gap: 10px;
      cursor: pointer;
      transition: $transition;

      &:hover {
        background-color: rgba(32, 78, 51, 0.1);
      }

      &:not(:last-child) {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      }

      figure {
        height: 40px;
        width: 40px;
        min-width: 40px;
        border-radius: 50%;
        overflow: hidden;
        margin: 0;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }

      h3 {
        font-size: 14px;
        font-weight: 700;
        margin: 0 0 2px;
        color: $c_black;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: 15px;

        span {
          font-size: 12px;
          font-weight: 500;
          margin: 0;
          color: $c_text_grey;
        }
      }

      p {
        font-size: 12px;
        font-weight: 500;
        margin: 0;
        color: #555;
      }
    }
  }
}

// Main
.dashboard_main {
  .sub-layout {
    padding: calc(70px + 25px) 20px 25px;
    max-width: 100% !important;
  }

  .cards {
    padding: 20px 20px;
    box-shadow: $shadow3;
    border-radius: 10px;
    color: $c_black;
    overflow: initial;
    border: 1px solid #F1F1F4;

    &_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
      flex-wrap: wrap;

      &_right {
        display: flex;
        align-items: center;
        gap: 15px;
        justify-content: flex-end;
      }
    }
  }
}

.MuiTextField-root {
  width: 100%;
}

.custom-input {
  width: 100%;
  min-height: 150px;
  height: 100%;
  background: #ccc !important;
}



.MuiAccordion-root {
  background: transparent !important;


  .MuiAccordionSummary-content {
    color: #fff;
  }
}

.update-btn {
  margin-right: 10px !important;
}

.footer-list {
  display: flex;
  list-style: none;
  padding: 0;


  li {
    padding: 0 5px;
    font-size: 14px;

    &:first-child {
      padding-left: 0px;
    }
  }
}
.ql-toolbar.ql-snow + .ql-container.ql-snow {
  height: 112px;
}
.react-Quill .ql-toolbar.ql-snow + .ql-container.ql-snow{
  height: 447px;
}
.like-count {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main_loyout {
  .dashboard {
    margin-bottom: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;


    .heading-bread {
      h1 {
        font-size: 20px;
        font-weight: 800;
        letter-spacing: 0.5px;
        line-height: 1.3;
        margin: 0;
        width: 100%;
      }

      ol {
        display: none;
        li {
          font-size: 14px;
          text-transform: capitalize;
        }
      }
    }
  }
}



.parent-box {
  display: flex;
  flex-direction: column;
  position: relative;

  button {
    // align-self: flex-end;
    margin-right: 2px;
  }

  .filter-options {
    width: 410px;
    box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, .15);

    margin-top: 10px;
    position: absolute;
    right: 0;
    top: 30px;
    background: #fff;
    z-index: 9;

    .filter-div {
      padding: 0 20px 20px 20px;
    }

    h3 {
      margin-top: 0px;
      border-bottom: 1px solid #ccc;
      padding: 15px 20px;
      font-size: 15px;
      font-weight: 600;
    }

    label {
      margin-bottom: 5px;
    }

    select {
      margin-bottom: 20px;
      background: #f9f9f9;
      border-color: #f9f9f9;
    }

    .button-div {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 15px;

      button {
        text-transform: capitalize !important;
      }
    }
  }
}

.sortingArrow {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  cursor: pointer;
}

.dashGrid {
  .dashGrid_item {
    .upr_items{
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      justify-content: space-between;
      margin-bottom: 10px;
    }
    span {
      height: 8px;
      border-radius: 20px;
      background-color: #D9D9D9 !important;
      &:first-child {
        background-color: #000000 !important; 
      }
    }
    text-align: left;
    gap: 20px;
    border-bottom: 2px solid $c_primary;
    cursor: pointer;
    transition: $transition;
    padding-top: 30px !important;
    padding-bottom: 30px !important;

    // &:hover {
    //   transform: translateY(-5px);
    // }

    .svg_icn {
      height: 45px;
      width: 45px;
      min-width: 45px;
      padding: 10px;
      color: $c_white;
      background: $c_primary;
      border-radius: 5px;
      box-sizing: border-box;
    }

    h3 {
      font-size: 14px;
      color: $c_primary_text;
      font-weight: 600;
      letter-spacing: 0.3px;
      margin: 0;
      line-height: 1;
    }

    h4 {
      font-size: 25px;
      color: $c_black;
      padding-top: 15px;
      margin: 0;
      line-height: 1;
      font-family: "Inter", "Montserrat", sans-serif !important;
    }
  }
}

.dashGraph {
  .dashGraph_item {
    text-align: left;

    h2 {
      font-size: 18px;
      font-weight: 800;
      letter-spacing: 0.5px;
      line-height: 1.3;
      margin: 0 0 15px;
      padding-bottom: 15px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      color: $c_black;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

.search_bar {
  width: 260px;
  position: relative;

  .search_icon {
    position: absolute;
    width: auto;
    height: auto;
    margin: 0;
    max-height: initial;
    color: #999;
    left: 10px;
  }

  input {
    padding-left: 40px;
    background-color: #f9f9f9;
    border-color: #f9f9f9;
    color: #4B5675;
    transition: color .2s ease;
  }
}

.table_container {
  margin-top: 20px;

  table {
    width: 100%;

    tr {
      :is(th, td) {
        font-size: 13px;
        font-weight: 500;
        font-family: "Inter", "Montserrat", sans-serif !important;
        color: $c_black;
        line-height: 1.2;
        letter-spacing: 0;
        border-bottom: 1px dashed #F1F1F4;
        padding: 8px 12px;
        white-space: nowrap;
        box-sizing: border-box;
      }

      td {
        color: #78829d;

        .banner_link {
          white-space: nowrap !important;
          overflow: hidden !important;
          text-overflow: ellipsis !important;
          max-width: 200px !important;
          display: block;
          margin: 0 auto;
        }
      }

      th {
        font-weight: 600;
        color: #99A1B7;
        font-size: 12px;
        text-transform: uppercase;


        &:first-child {
          &:has(input[type="checkbox"]) {
            width: 60px;
            min-width: 60px;
          }
        }

        &:nth-child(2):contains("S.No") {
          width: 70px;
          min-width: 70px;
        }

        &:first-child,
        &:nth-child(2) {
          &:contains("S.No") {
            width: 70px;
            min-width: 70px;
          }
        }
      }
    }

    .user_img {
      margin: 0;

      img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        object-fit: cover;
        object-position: center;
      }
    }

    .bnr_img {
      margin: 0;

      img {
        width: 70px;
        height: 55px;
        border-radius: 4px;
        object-fit: cover;
        object-position: center;
      }
    }

    .table_actions {
      display: inline-flex;
      align-items: center;
      gap: 5px;

      button {
        height: 35px;
        width: 35px;
        min-width: 35px;
        padding: 0;
        border-radius: 50%;
        background-color: #f5f5f5;
        color: $c_secondary;
        transition: $transition;

        svg {
          font-size: 18px;
        }

        &:hover {
          color: $c_white;
          background-color: $c_primary;
        }
      }
    }

    .MuiCheckbox-root {
      padding: 0;
      color: $c_primary;
    }
  }

  .MuiFormControl-root {
    select {
      height: 35px;
      padding: 2px 20px 2px 10px !important;

      &+svg {
        font-size: 20px;
        right: 0;
      }
    }
  }
}

.upload_image {
  label {
    display: inline-flex;
  }

  &_btn[class*="btn"] {
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 0;
    padding: 0;

    &:hover {
      background-color: transparent;
    }

    img {
      height: 100px;
      width: 100px;
      border-radius: 50%;
      object-fit: cover;
    }

    svg {
      height: 30px;
      width: 30px;
      border-radius: 50%;
      color: $c_white;
      background-color: $c_primary;
      padding: 6px;
      box-sizing: border-box;
      margin-top: -15px;
    }
  }

  &_preview {
    display: inline-flex;
    position: relative;
    align-items: center;
    flex-direction: column;

    img {
      height: 100px;
      width: 100px;
      border-radius: 50%;
      object-fit: cover;
    }

    svg {
      height: 30px;
      width: 30px;
      border-radius: 50%;
      color: $c_white;
      background-color: $c_red;
      padding: 6px;
      box-sizing: border-box;
      margin-top: -15px;
    }
  }
}

.view_box {
  .profile_img {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    margin: 0;

    img {
      height: 100%;
      width: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  &_list {
    .MuiBox-root {
      &:not(:last-child) {
        margin-bottom: 20px;
      }

      h5 {
        color: $c_primary_text;
        font-size: 13px;
        font-weight: 600;
        margin-bottom: 5px;
      }

      p {
        color: $c_black;
        font-size: 14px;
        font-weight: 600;
        margin: 0;
      }
    }
  }

  .detail_title {
    font-size: 16px;
    font-weight: 700;
  }
}

.custom_tabs {
  .custom_tabs_links {
    margin-bottom: 20px;

    .MuiTabs-flexContainer {
      display: inline-flex;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      padding: 10px;
      gap: 5px;
      flex-wrap: wrap;

      +.MuiTabs-indicator {
        display: none;
      }

      button {
        min-height: 40px;
        padding: 0 8px;
        font-size: 14px;
        font-weight: 600;
        color: $c_primary_text;
        text-transform: capitalize;
        border-radius: 5px;
        line-height: normal;
        transition: $transition;

        &:hover {
          color: $c_black;
          background-color: rgb(32 78 51 / 10%);
        }

        &.Mui-selected {
          color: $c_white;
          background-color: $c_primary;
        }
      }
    }
  }

  [role="tabpanel"] {
    >.MuiBox-root {
      padding: 0;
    }
  }
}

.revert_dialog {
  .MuiDialog-paper {
    max-width: 400px;
    width: 100%;

    .MuiDialogContent-root {
      padding: 30px;
    }
  }

  .dialog_title {
    font-size: 18px;
    font-weight: 700;
    color: $c_black;
    margin-bottom: 10px;
  }

  .form_btn {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
}

.mn_hdng {
  color: $c_black !important;
  font-size: 24px !important;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.upload_image_bnr,
.upload_image_preview2 {
  border: 1px solid rgba(0, 0, 0, 0.2);
  width: 49%;
  border-radius: 3px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  label {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    cursor: pointer;
  }
}

.upload_image_preview2 {
  img {
    width: 100%;
    height: 100%;
  }

  svg {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
  }
}

.switch_flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.select_div {

  div {
    font-size: 13px;
    font-weight: 500;
    font-family: "Inter", "Montserrat", sans-serif !important;
    color: #252f4a;
    line-height: 1.2;
    letter-spacing: 0;
    padding: 8px 12px;
    white-space: nowrap;
    box-sizing: border-box;
    text-align: left;
    background-color: #f9f9f9;
  }

  fieldset {
    border: none !important;
  }
}

ul {
  .action-item {
    font-size: 13px;
    font-weight: 500;
    font-family: "Inter", "Montserrat", sans-serif !important;
    line-height: 1.2;
  }
}

.sub_headings {
  color: $c_secondary;
  font-size: 20px !important;
  font-weight: 600 !important;
}

.Mui-disabled {
  input {
    -webkit-text-fill-color: black !important;
  }
}

.link {
  color: $c_secondary;

  &:hover {
    color: $c_primary;
  }
}

@media (max-width: 1199px) {
  main.dashboard_main.mn-lyout.MuiBox-root.css-1cepvel {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .btn_primary {
    height: 45px !important;
  }

  :is(input, select, input.form-control) {
    height: 45px !important;
  }
}

@media (max-width: 600px) {

  .upload_image_bnr,
  .upload_image_preview2 {
    width: 100%;
  }

  .custom_tabs {
    .custom_tabs_links {
      .MuiTabs-flexContainer {
        width: 100%;
        box-sizing: border-box;
        justify-content: center;

        button {
          max-width: 100%;
        }
      }
    }
  }
}

.text_field textarea {
  font-weight: 500;
  height: 50px;
  box-sizing: border-box;
  font-size: 14px;
  border-radius: 5px !important;
  border: 1px solid $c_primary;
  box-shadow: none !important;
  padding: 10px;
}

.text_field p,
.err_msg {
  color: red !important;
  font-size: 14px !important;
  margin: 0 !important;
  font-weight: 400;
}

.loader_loading {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 50%;
  left: 0%;

  figure {
    width: 60px;
    height: 60px;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
}

.flexdiv {
  display: flex;
  justify-content: center;
  padding-top: 20px;
  gap: 20px;

  button {
    background-color: $c_primary;
    border: 1px solid $c_primary;
    color: $c_white;

    &:hover {
      background-color: $c_secondary;
      color: $c_white;
      border: 1px solid $c_secondary;
    }
  }
}

.bordered-card {
  border: 1px solid black;
  border-radius: 10px;
  padding: 20px;
}

//pagination
.pagination {
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h3 {
    width: 100%;
    min-height: 280px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Inter";
    font-size: 18px;
  }

  p {
    font-size: 14px;
    font-family: "Inter";

    span {
      font-family: "bold";
      color: black;
    }
  }

  .pages {
    display: flex;

    .prevbtn {
      background: no-repeat;
      border: none;
    }

    svg {
      font-size: 12px;
      border: 0.5px solid #a5a5a5;
      padding: 6px;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
      margin-right: 10px;
      cursor: pointer;
      color: black;
    }

    .count {
      display: flex;
      align-items: center;
      margin-right: 10px;
      background: none;
      /* padding: 6px; */
      border-radius: 24px;
      border: 0.5px solid #fff;

      .actv {
        background: $c_secondary;
        color: white;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        cursor: pointer;
      }

      .inActv {
        background: transparent;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        color: black;
        cursor: pointer;
        border: none;
      }
    }
  }
}

.cross_btn {
  font-size: 15px !important;
  cursor: pointer !important;
  position: absolute;
  width: auto;
  height: auto;
  margin: 0;
  max-height: initial;

  right: 10px;

  svg {
    width: 20px !important;
    height: 20px !important;
    color: $c_primary_text;
  }
}

.Mui-disabled {
  cursor: not-allowed;
}

.no_permissions {
  width: 70%;
  margin: 0 auto;
  text-align: center;
  padding: 100px 0;

  figure {
    height: 200px;
    width: 200px;
    margin: 0 auto;

    // display: flex;
    // justify-content: center;
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  h3 {
    font-size: 26px;
  }

  p {
    width: 70%;
    margin: 0 auto;
    line-height: 26px;
    margin-top: -10px;
  }
}

.tips {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  list-style-type: disc !important;
  padding-left: 0 !important;

  button {
    margin-right: 10px;
  }
}

.notfcn_msg {
  &>p {
    max-width: 470px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
  }
}


.status {
  padding: 2px 6px;
  font-size: 12px;
  font-weight: bold;
  border-radius: 4px;
}

.status.Active {
  background-color: #dfffea;
  color: #17c653;
}

.status.Banned {
  background-color: #ffdfdf;
  color: #c61717;
}

.status.Disabled {
  background-color: #f3f3f3;
  color: #838383;
}
.parent-border-box{
  max-height: 230px;
  overflow-y:scroll ;
}

.border-box {
  border-bottom: 1px solid #ccc;
  padding: 10px 0;

  &:last-child{
    border-bottom: none;
  }

  h4 {
    margin: 3px;
    font-size: 16px;
    font-weight: 600;
  }

  p {
    font-size: 14px;
    color: rgb(120, 130, 157);
    font-weight: 400;
    margin: 3px;
  }
}

.acc-details {
  color: #9a9cae !important;
  padding: 0px !important;
  padding-left: 65px !important;
}

.custom-acc {
  padding-left: 20px !important;
  padding-right: 20px !important;

  .MuiAccordionDetails-root {
    color: #9a9cae !important;
    padding-left: 65px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.3px;
    line-height: 1.2;
    transition: all 0.3s ease-in-out 0s;
  }

  .MuiSvgIcon-root {
    fill: #fff !important;
  }

  .MuiAccordionSummary-content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.3px;
    line-height: 1.2;
    transition: all 0.3s ease-in-out 0s;
  }

  .cust-menu-icon {
    .MuiSvgIcon-root {
      fill: #393939 !important;
    }
  }
}

.menu-heading {
  color: #fff;
  padding-left: 20px;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 10px;
}

.cust-menu-icon {
  background: #fff;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;

  .cust-menu-icon .MuiSvgIcon-root {
    width: 20px;
    height: 20px;
    /* color: #ccc; */
    fill: #393939 !important;
  }

}

.username {
  font-weight: bold;
  font-size: 13px;
  color: #252f4a;
  cursor: pointer;

  &:hover {
    color: $blue;
  }
}

.image-preview {
  display: flex;
  flex-direction: column;
  position: relative;

  .close-icon {
    position: absolute;
    right: 10px;
    top: 10px;
    margin-left: auto;
    cursor: pointer;
    background: #fff;
    display: flex;
    border-radius: 50%;
    padding: 5px;
  }

  img {
    aspect-ratio: 16/9;
    width: 100%;
    object-fit: cover;
    // height: auto;
    // max-width: 100%;
    // object-fit: fill;
  }
  // figure {
    // margin: 0;
    // padding: 20px;
    // border: 1px solid #ccc;
   

   
  // }
}

.custom-textarea{
  height: 100% !important;
  .MuiInputBase-root{
    height: 100% !important;}

}

.marginstart{
  margin-left: 10px !important;
}

.label-custom {
  font-size: 14px;
  margin-bottom: 5px;
  display: flex;
}

#headlineText-helper-text {
  color: red
}


.css-1wc848c-MuiFormHelperText-root{
  color: red !important;
}

.Accordion-Resources{
  background-color: #0d0e12;
}